import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import React, { useContext } from 'react'
import { ReactComponent as PeopleIcon } from '../../personality_test/assets/people-icon.svg'
import PersonalityCarousel from '../components/PersonalityCarousel'
import PersonalityFactorCard from '../components/PersonalityFactorCard'
import { Button } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { authContext } from 'contexts/AuthContext'
import LogIn from '../components/LogIn'
import { myAccount } from 'services/api/authentication'
import { useState } from 'react'
import { useEffect } from 'react'
import Loader from 'react-loader-spinner'
import ShareResultCard from '../components/ShareResultCard'
import TakeTestCard from '../components/TakeTestCard'
import html2pdf from 'html2pdf.js'
import DownloadPersonalityCard from '../components/DownloadPersonalityCard'
import { ReactComponent as AdnexioLogo } from '../../../assets/logos/adnexio-official-logo.svg'

const TestResult = () => {
  const {
    auth: { isAuth },
  } = useContext(authContext)
  const location = useLocation()
  const data = location.state
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(false)

  const resultLink = 'You are a chameleon'
  const inviteLink = 'http://www.reallylong.link/rll/PMdslJXQm12CuJbbn6mTPEdoh9tXdj/dpUzDPv_27O4yl'

  const getUserData = async () => {
    try {
      setLoading(true)
      const res = await myAccount()
      if (res.success) {
        setUserData(res.data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const downloadPDF = () => {
    const element = document.getElementById('testDownload')

    if (element) {
      const opt = {
        filename: 'work_personality_test.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      }

      html2pdf().set(opt).from(element).save()
    } else {
      console.error('Target element not found!')
    }
  }

  useEffect(() => {
    if (isAuth) {
      getUserData()
    }
  }, [isAuth])

  return (
    <>
      {/* <Header page='disableBottom' /> */}
      <NewHeader />
      <div className="relative flex flex-col justify-center gap-[32px] bg-adx-defaultBackground px-5 pt-6 pb-[50px] lg:flex-row lg:px-[60px] lg:pt-8">
        <div className="relative flex flex-col w-full lg:w-2/3">
          <div className="flex w-full flex-col rounded-[4.5px] border-[1px] border-adx-addBackground bg-adx-white">
            <div className="relative flex h-[200px] flex-col items-center justify-between overflow-clip border-b-[1px] border-adx-addBackground p-6 sm:h-[107px] sm:flex-row">
              <div className="flex flex-col gap-1">
                <p className="text-sm text-adx-darkerGray">WORK PERSONALITY TEST</p>
                {loading ? (
                  <p className="text-xl font-medium capitalize text-adx-darkText">...</p>
                ) : (
                  <p className="text-xl font-medium capitalize text-adx-darkText">
                    {isAuth && userData ? userData?.name : 'Your result'}
                  </p>
                )}
              </div>
              <PeopleIcon
                width={'145px'}
                className="absolute -bottom-5 sm:bottom-auto sm:top-2 sm:right-8"
              />
            </div>
            <PersonalityCarousel data={data} isLoggedIn={isAuth} />
          </div>
          <p className="mb-6 text-center mt-9 text-adx-darkerGray lg:mb-8 lg:mt-11">
            Personalities breakdown
          </p>
          <div className="flex flex-col gap-6">
            {data.slice(0, isAuth ? data.length : 2).map((item, index) => (
              <PersonalityFactorCard key={index} index={index} data={item} />
            ))}
          </div>
          <div className="mt-6 mb-8 border-[1px] border-adx-addBackground lg:mt-8 lg:mb-12" />
          <div className="overflow-clip rounded-[4.5px] border-[1px] border-adx-addBackground">
            <div className="flex flex-col justify-between gap-8 border-b-[1px] border-adx-addBackground bg-adx-white p-6 sm:flex-row sm:items-center">
              <div className="flex flex-col gap-3 sm:w-[500%] sm:gap-0">
                <p className="font-medium">Download your result</p>
                <p className="text-sm text-adx-darkerGray">
                  Click to download now for your most recent results.
                </p>
              </div>
              <Button
                onClick={downloadPDF}
                variant="contained"
                fullWidth
                style={{ whiteSpace: 'nowrap' }}
              >
                Download
              </Button>
            </div>
            <div className="flex flex-col justify-between gap-8 p-6 bg-adx-white sm:flex-row sm:items-center">
              <div className="flex flex-col gap-3 sm:w-[500%] sm:gap-0">
                <p className="font-medium">Re-evaluate your result</p>
                <p className="text-sm text-adx-darkerGray">
                  Embrace your growth and transformation. Take the test again for fresh results.
                </p>
              </div>
              <Button
                href="/personality-test/start"
                variant="contained"
                fullWidth
                style={{ whiteSpace: 'nowrap' }}
              >
                Retake test
              </Button>
            </div>
          </div>
          {!isAuth && <LogIn />}
        </div>
        {/* <ShareResultCard result={resultLink} invite={inviteLink} /> */}
        {/* <TakeTestCard/> */}
      </div>
      
      {/* Hidden - only visible when download */}
      <div className="hidden">
        <div id="testDownload" className="px-4 bg-white">
          <div className='flex items-end justify-between px-4 pb-4 pt-2 border-b-[1px] border-adx-addBackground'>
            <div className="flex flex-col gap-1">
              <p className="text-sm text-adx-darkerGray">WORK PERSONALITY TEST</p>
              {loading ? (
                <p className="font-medium capitalize text-adx-darkText">...</p>
              ) : (
                // <Loader type='ThreeDots' color='black' width={24} height={24} />
                <p className="font-medium capitalize text-adx-darkText">
                  {isAuth && userData ? userData?.name : 'Your result'}
                </p>
              )}
            </div>
            <AdnexioLogo/>
          </div>
          <div className='mb-4 font-medium text-center'>
            <p>Personalities Breakdown</p>
          </div>
          <div className="flex flex-col gap-2">
            {data.slice(0, isAuth ? data.length : 2).map((item, index) => (
              <DownloadPersonalityCard key={index} index={index} data={item} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default TestResult
