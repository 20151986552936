import Bugsnag from "@bugsnag/js";
import { createContext, useEffect, useState } from "react";
import { ipLookup, getIpAddress } from "services/api/ip";

export const IpContext = createContext()

const IpContextProvider = (props) => {
    const [ipCountry, setIpCountry] = useState(null)
    const [ipLoading, setIpLoading] = useState(false)
    
    const fetchData = async () => {
        try {
            setIpLoading(true)
            await getIpAddress()
                .then(async (res) => {
                    if (res) {
                        await ipLookup(res)
                            .then((res) => {
                                setIpCountry(res.country);
                            })
                    }
                })
        } catch (e) {
            Bugsnag.notify(e)
        } finally {
            setIpLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <IpContext.Provider
            value={{
                ipCountry,
                ipLoading,
            }}
        >
            {props.children}
        </IpContext.Provider>
    )
}

export default IpContextProvider