import { Button } from '@mui/material'
import Header from 'components/smart/Header/view/Header'
import NewHeader from 'components/smart/Header/view/NewHeader'
import React from 'react'
import answerImg from '../../personality_test/assets/answer-img.png'
import questionImg from '../../personality_test/assets/question-img.png'
import grassIcon from '../../personality_test/assets/grass-icon.svg'
import peopleIcon from '../../personality_test/assets/people-icon.svg'
import shieldIcon from '../../personality_test/assets/shield-icon.svg'
import videoIvImg from '../../personality_test/assets/video-interview-2.png'
import videoIvMobile from '../../personality_test/assets/video-interview-mobile.png'

const PersonalityTest = () => {
    return (
        <>
            <NewHeader/>
            {/* <Header page='disableBottom'/> */}
            
            {/* Landing page */}
            <div className='text-adx-darkText'>
                <div className='from-[#B6A4EF] flex flex-col-reverse md:flex-row gap-10 md:gap-20 justify-between to-adx-softGreen bg-gradient-to-tr via-white px-5 md:px-[60px] py-10 md:py-[80px]'>
                    <div className='flex flex-col gap-5 md:w-1/2'>
                        <p className='text-2xl md:text-[34px] font-semibold'>Unlock your work personality insights</p>
                        <p className='text-sm'>Assess your personality at work by taking this 10-minute work personality test with us. This test measures 5 different dimensions of your work personality.</p>
                        <Button href='/personality-test/start' variant='contained' className='px-5 w-fit'>
                            Get started
                        </Button>
                    </div>
                    <div className='relative md:w-1/2 h-[250px] md:h-[300px]'>
                        <img src={questionImg} alt="Question" className=' w-[80%] md:w-auto absolute md:right-[20%]' />
                        <img src={answerImg} alt="Answer" className='w-[80%] md:w-auto absolute bottom-0 right-0 z-10' />
                    </div>
                </div>
                <div className='flex flex-col items-center py-[80px] px-5 md:px-0 gap-12'>
                    <p className='text-2xl md:text-[34px] font-semibold text-center'>Why personality test?</p>
                    <div className='flex flex-col gap-5 lg:flex-row'>
                        <div className='w-[300px] h-[250px] aspect-square bg-adx-defaultBackground rounded-[20px] p-[30px] relative'>
                            <p className='text-xl font-medium'>Get to know yourself better</p>
                            <img src={peopleIcon} alt="People icon" className='absolute bottom-0 right-[30px]' />
                        </div>
                        <div className='w-[300px] h-[250px] aspect-square bg-adx-defaultBackground rounded-[20px] p-[30px] relative'>
                            <p className='text-xl font-medium'>Build confidence at your workplace</p>
                            <img src={shieldIcon} alt="Shield icon" className='absolute bottom-0 right-[30px]' />
                        </div>
                        <div className='w-[300px] h-[250px] aspect-square bg-adx-defaultBackground rounded-[20px] p-[30px] relative'>
                            <p className='text-xl font-medium'>Find a workplace compatible to your personality</p>
                            <img src={grassIcon} alt="Grass icon" className='absolute bottom-0 right-[30px]' />
                        </div>
                    </div>
                </div>
                <div className='px-5 md:px-[60px] pt-5 pb-10 md:pb-[60px]'>
                    <div className='flex flex-col-reverse md:flex-row justify-between bg-adx-defaultBackground rounded-2xl px-5 pb-10 md:p-[60px]'>
                        <div className='flex flex-col md:w-1/2'>
                            <p className='text-2xl md:text-[34px] font-semibold mb-5'>Explore <br className='md:hidden' /> video interview</p>
                            <p className='mb-10 text-sm'>Record a 5 minutes video interview with NEX, our AI Career Coach and NEX would match you with the best jobs for you.</p>
                            <Button href='/video-interview' variant='contained' className='px-5 w-fit'>Learn more</Button>
                        </div>
                        <img src={videoIvImg} alt="Video interview" className='object-contain hidden md:block -m-[60px]' />
                        <img src={videoIvMobile} alt="Video interview" className='object-contain md:hidden mt-4 mb-[35px]' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PersonalityTest